import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/HeroService"
import { useStaticQuery, graphql } from "gatsby"
import Works from "../components/Works"
import Question from "../components/Question"
import ImgTextLeftServices from "../components/ImgTextLeftServices"
import ImgTextRightServices from "../components/ImgTextRightServices"

const Bathroom = () => {
    const { wpPage } = useStaticQuery(graphql`
    {
        wpPage(title: {eq: "Bathroom"}) {
            bathroomservice {
              sliderText
              firstServiceImg {
                altText
                sourceUrl
              }
              firstServiceHeading
              firstServiceImgAlt
              firstServiceTag
              firstServiceText
              secondServiceHeading
              secondServiceImg {
                altText
                sourceUrl
              }
              secondServiceImgAlt
              secondServiceTag
              secondServiceText
              thirdServiceHeading
              thirdServiceImg {
                altText
                sourceUrl
              }
              thirdServiceImgAlt
              thirdServiceTag
              thirdServiceText
            }
          }
    }
  `)
    return (
  <Layout>
    <div className="service-page">
      <Hero active="bathroom" herotitle="Bathroom" herotext={wpPage.bathroomservice.sliderText} ></Hero>
      <ImgTextLeftServices headingtag={wpPage.bathroomservice.firstServiceTag} heading={wpPage.bathroomservice.firstServiceHeading} text={wpPage.bathroomservice.firstServiceText} obrazek={wpPage.bathroomservice.firstServiceImg.sourceUrl} obrazekalt={wpPage.bathroomservice.firstServiceImg.altText}></ImgTextLeftServices>
      <ImgTextRightServices classnamee="service-page-prelast" headingtag={wpPage.bathroomservice.secondServiceTag} heading={wpPage.bathroomservice.secondServiceHeading} text={wpPage.bathroomservice.secondServiceText} obrazek={wpPage.bathroomservice.secondServiceImg.sourceUrl} obrazekalt={wpPage.bathroomservice.secondServiceImg.altText}></ImgTextRightServices>
      <ImgTextLeftServices classnamee="service-page-last" headingtag={wpPage.bathroomservice.thirdServiceTag} heading={wpPage.bathroomservice.thirdServiceHeading} text={wpPage.bathroomservice.thirdServiceText} obrazek={wpPage.bathroomservice.thirdServiceImg.sourceUrl} obrazekalt={wpPage.bathroomservice.thirdServiceImg.altText}></ImgTextLeftServices>
      <Works></Works>   
      <Question></Question>
    </div>
  </Layout>  
    )
    }

export default Bathroom;
 